<template>
  <article class="p-error">
    <div class="l-container">
      <h1 class="p-error__title" v-t="'404 Not Found'" />
      <div class="p-error__content">
        <div class="content">
          <p>
            {{ $t('指定されたページが、見つかりませんでした。') }}<br />{{
              $t(
                'URLが間違っているかページの移動または削除等の理由により閲覧することができません。'
              )
            }}<br />{{ $t('トップページより再度ご確認ください。') }}
          </p>
        </div>
      </div>
      <app-link
        class="p-error__link c-button"
        :to="{ name: 'Index', params: { portalId } }"
        v-t="'トップページへ'"
      />
    </div>
  </article>
</template>

<script>
export default {
  name: 'ErrorPresentation',
  metaInfo: {
    htmlAttrs: {
      class: '-background-white',
    },
  },
}
</script>
