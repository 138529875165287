<template>
  <div>
    <ErrorPresentation error-code="404" />
  </div>
</template>

<script>
import { defaultLayoutViewMixin } from '@/mixins'
import ErrorPresentation from '@/components/error/ErrorPresentation.vue'

export default {
  name: 'Error',
  mixins: [defaultLayoutViewMixin],
  components: {
    ErrorPresentation,
  },
  metaInfo() {
    return {
      title: this.$t('404 Not Found'),
    }
  },
}
</script>
